import { createRouter, createWebHistory, RouteMeta } from "vue-router";
import type { Router, RouteRecordRaw } from "vue-router";
import type { I18n, Locale, Composer } from "vue-i18n";
import Login from "@/views/account/Login.vue";
import Manage from "@/views/account/Manage.vue";
import ForgotPassword from "@/views/account/ForgotPassword.vue";
import OrderList from "@/views/orders/OrderList.vue";
import Order from "@/views/orders/Order.vue";
import WizardSteps from "@/views/fabricselector/WizardSteps.vue";
import CreateOrder from "@/views/orders/CreateOrder.vue";
import CreateMarking from "@/views/folders/CreateMarking.vue";
import CreateFolder from "@/views/folders/CreateFolder.vue";
import FolderView from "@/views/folders/FolderView.vue";
import OrderSidebar from "@/components/OrderSidebar.vue";
import Punchout from "@/views/account/Punchout.vue";
import CreateMarkingImage from "@/views/markings/CreateMarkingImage.vue";
import MarkingImageList from "@/views/markings/MarkingImageList.vue";
import ChangePassword from "@/views/account/ChangePassword.vue";
import EditOrder from "@/views/orders/EditOrder.vue";
import EditMarkingFabric from "@/views/EditMarkingFabric.vue";
import PreviewOrder from "@/views/orders/PreviewOrder.vue";
import SearchOrder from "@/views/orders/SearchOrder.vue";
import OrderCompleted from "@/views/orders/OrderCompleted.vue";
import { MenuItemType } from "@/components/OrderSidebar";
import createNavigationGuards from "./NavigationGuards";
import { AccountStore } from "@/store/accountstate";
import Help from '@/views/account/Help.vue';
import UserOrderSharing from "@/views/orders/UserOrderSharing.vue";
import EditFolder from "@/views/folders/EditFolder.vue";
import ChooseApp from "@/views/account/ChooseApp.vue";
import { AccountService } from "@/axios/accountservice";
import { AccountToken } from "@/models/accountmodels";
import Ping from "@/views/ping/Ping.vue";


export interface Meta extends RouteMeta {
    allowAnonymous: boolean;
    orderSideBar: MenuItemType;
    hideSideBar: boolean;
};

export function setupRouter(i18n: I18n): Router {
    const locale: Locale = i18n.mode === "legacy"
        ? i18n.global.locale
        : ((i18n.global as unknown) as Composer).locale.value;
        
    // setup routes
    const routes: RouteRecordRaw[] = [
        {
            path: "/:locale/",
            name: "home",
            redirect: () => {
                return { name: "login" }
            },
            meta: {
                allowAnonymous: false
            } as Meta
        },
        {
            path: "/:locale/account/login",
            name: "login",
            component: Login,
            meta: {
                allowAnonymous: true,
                title: 'Our Tools' 
            } ,
            async beforeEnter(to, from, next) {
                if(AccountStore.currentUser != null) 
                    {
                        if (AccountStore.currentUser?.acousticUser && AccountStore.currentUser?.svenUser || AccountStore.currentUser?.acousticUser && AccountStore.currentUser?.internalSalesSven) {
                            next({
                                name: "chooseapp",
                                params: { locale },
                            });
                        }
                        else if (AccountStore.currentUser?.acousticUser) {
                            const refreshToken = AccountStore.currentToken?.refreshToken;
                           
                            if (!refreshToken) {
                                throw new Error('Missing refresh token');
                            }
        
                            const accountToken: AccountToken = {
                                refreshToken: refreshToken
                            };
        
                            try {
                                const redirectUrl = await AccountService.acousticRedirectLink(accountToken);
                        
                                if (!redirectUrl) {
                                    throw new Error('Invalid redirect URL');
                                }
                        
                                window.location.href = redirectUrl;
                            } catch (error) {
                                console.error('Error during acoustic user redirect:', error);
                            }
                        }
                        else {
                            next({
                                name: "account",
                                params: { locale },
                            });
                        }
                    }
                else {
                    next();
                }
            }
        },
        {
            path: "/:locale/account/forgot-password",
            name: "forgotpassword",
            component: ForgotPassword,
            meta: {
                allowAnonymous: true
            } as Meta
        },
        {
            path: "/:locale/account/choose-application",
            name: "chooseapp",
            component: ChooseApp,
            meta: {
                allowAnonymous: false,
                title: 'Our Tools' 
            }
        },
        {
            path: "/:locale/account",
            name: "account",
            component: Manage,
            meta: {
                allowAnonymous: false
            } as Meta
        },
        {
            path: "/:locale/help",
            name: "help",
            component: Help,
            meta: {
                allowAnonymous: false
            } as Meta
        },
        {
            path: "/:locale/account/change-password",
            name: "changepassword",
            component: ChangePassword,
            meta: {
                allowAnonymous: false
            } as Meta
        },
        {
            path: "/:locale/orders",
            name: "orders",
            component: OrderList,
        },
        {
            path: "/:locale/orders/search",
            name: "searchorders",
            props: route => ({ query: route.query.q }),
            component: SearchOrder
        },
        {
            path: "/:locale/order/:order",
            name: "order",
            components: {
                default: Order,
            },
            meta: {
                allowAnonymous: false
            } as Meta,
        },
        {
            path: "/:locale/order/:order/preview/finished",
            name: "previewOrderFinished",
            components: {
                default: PreviewOrder,
            },
        },
        {
            path: "/:locale/order/:order/preview/init",
            name: "previewOrderInit",
            components: {
                default: PreviewOrder,
                sidebar: OrderSidebar
            },
            meta: {
                orderSideBar: MenuItemType.Markings,
                hideSideBar:true,
            } as Meta,
        },
        {
            path: "/:locale/order/:order/preview/completed",
            name: "previewOrderComplete",
            component: OrderCompleted
        },
        {
            path: "/:locale/order/:order/marking/:marking/:step?",
            name: "marking",
            components: {
                default: WizardSteps,
                sidebar: OrderSidebar
            },
            meta: {
                orderSideBar: MenuItemType.Markings,
                hideSideBar: true
            } as Meta,
        },
        {
            path: "/:locale/order/:order/fabric/:product/:variant",
            name: "edit-marking-fabric",
            components: {
                default: EditMarkingFabric,
                sidebar: OrderSidebar
            },
            meta: {
                orderSideBar: MenuItemType.Fabrics,
                hideSideBar: true
            } as Meta
        },
        {
            path: "/:locale/order/create",
            name: "createOrder",
            components: {
                default: CreateOrder
            }
        },
        {
            path: "/:locale/order/:order/edit",
            name: "editOrder",
            components: {
                default: EditOrder,
                sidebar: OrderSidebar
            },
            meta: {
                orderSideBar: MenuItemType.Markings,
                hideSideBar: true
            } as Meta,
        },
        {
            path: "/:locale/order/:order/folder/:folder/create-marking",
            name: "create-marking",
            components: {
                default: CreateMarking,
                sidebar: OrderSidebar
            },
            meta: {
                orderSideBar: MenuItemType.Markings,
                hideSideBar: true
            } as Meta,
        },
        {
            path: "/:locale/order/:order/folder/:folder/create",
            name: "createfolder",
            components: {
                default: CreateFolder,
                sidebar: OrderSidebar
            },
            meta: {
                orderSideBar: MenuItemType.Markings,
                hideSideBar: true
            } as Meta,
        },
        {
            path: "/:locale/order/:order/folder/:folder",
            name: "folderview",
            components: {
                default: FolderView,
                sidebar: OrderSidebar
            },
            meta: {
                orderSideBar: MenuItemType.Markings
            } as Meta,
        },
        {
            path: "/:locale/order/:order/folder/:folder/edit",
            name: "editfolder",
            components: {
                default: EditFolder,
                sidebar: OrderSidebar
            },
            meta: {
                hideSideBar: true
            } as Meta,
        },
        {
            path: "/:locale/account/punchout",
            name: "punchout",
            components: {
                default: Punchout,
            },
            meta: {
                allowAnonymous: true
            } as Meta,
        },
        {
            path: "/:locale/orders/share",
            name: "userordersharing",
            components: {
                default: UserOrderSharing,
            },
            meta: {
                allowAnonymous: false
            } as Meta,
        },
        {
            path: "/:locale/order/:order/marking/:marking/images",
            name: "marking-image-list",
            components: {
                default: MarkingImageList,
                sidebar: OrderSidebar
            },
            meta: {
                orderSideBar: MenuItemType.Images,
                hideSideBar: true
            } as Meta,
        },
        {
            path: "/:locale/order/:order/marking/:marking/create-image",
            name: "create-marking-image",
            components: {
                default: CreateMarkingImage,
                sidebar: OrderSidebar
            },
            meta: {
                orderSideBar: MenuItemType.Images,
                hideSideBar: true
            } as Meta,
        },
        {
            path: "/:locale/ping",
            name: "ping",
            components: {
                default: Ping
            },
            meta: {
                hideSideBar: true,
                allowAnonymous: true
            } as Meta,
        },
        {
            path: "/:pathMatch(.*)*",
            redirect: { name: "home", params: { locale } }
        }
    ];

    // create router instance
    const router = createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes
    });

    createNavigationGuards(router, i18n);

    router.beforeEach((to, from, next) => {
        if (to.meta.title) {
            document.title = to.meta.title;
        } else {
            document.title = 'Sven';
        }
        next();
    });

    return router;
}
