<template>
    <div>
      <h1>Ping Result</h1>
      <div v-if="result">
        <p>{{ result }}</p>
      </div>
      <div v-if="errorMessage">
        <p style="color: red;">Error: {{ errorMessage }}</p>
      </div>
    </div>
  </template>

<script src="./PingService.ts" lang="ts"></script>