import { computed, defineComponent, ref } from "vue";
import { useRoute, useRouter, onBeforeRouteUpdate, RouteLocationRaw } from "vue-router"
import { useI18n } from "vue-i18n";
import { OrderStore } from "@/store/orderstate";
import { Order, Marking, OrderLine, Folder } from "@/models/ordermodels";
import { ProductModel } from "@/models/stormmodels";
import { CalculateCurtainDropsApiOutput, ConfigurationType, FabricEditorSelectedData } from "@/models/configuratormodels";
import { OrderService } from "@/services/OrderService";
import ProductSelector from "./ProductSelector.vue";
import FabricEditor from "./FabricEditor.vue";
import Swal from "sweetalert2";
import { RouteParams } from "@/router/RouteParams";
import CurtainVisualizer from "@/components/CurtainVisualizer/CurtainVisualizer.vue";
import { CurtainVisualizerData } from "@/components/CurtainVisualizer/CurtainVisualizerData";
import { UIStore } from "@/store/ui";
import ExternalFabricForm from "./ExternalFabricForm.vue"
import ToggleSwitch from "@/components/ToggleSwitch.vue";

export default defineComponent({

    components: {
        CurtainVisualizer,
        ProductSelector,
        FabricEditor, 
        ExternalFabricForm,
        ToggleSwitch
    },

    name: "WizardSteps",

    setup() {

        const order = computed<Order>(() => {
            const order = OrderStore.order;
            if (order == null) {
                throw "Order not found";
            }
            return order;
        });

        const route = useRoute();
        const router = useRouter();
        const translate = useI18n().t;
        const calculationInProgress = ref<boolean>(false);
        const isExternalSupplier = ref<boolean>(false);
        const isFormValid = ref<boolean>(false);
        const externalFormRef = ref<InstanceType<typeof ExternalFabricForm> | null>(null);


        const getMarking = (id: string): Marking => {
            const marking = order.value.markings.get(id);
            if (!marking) {
                throw "Marking not found";
            }
            return marking;
        };        

        const getFolder = (id: string): Folder => {
            const folder = order.value.folders.get(id);
            if (!folder) {
                throw `Folder ${id} not found`;
            }
            return folder;
        };

        const handleOnCloseClick = (): void => {
            router.push({ name: "folderview", params: { order: order.value.id, folder: order.value.folder.id } });
        };

        const getOrderLine = (id: string): OrderLine | undefined => {
            return order.value.orderLines.find((item) => item.markingId === id);
        }

        const changeStep = (key: number): void => {
            for (const i in steps.value) {
                steps.value[i] = false;
            }
            if (key < 1) {
                router.push({ name: "folderview", params: { order: order.value.id, folder: order.value.folder.id } });
            }
            currentStep.value = Number(key);
            steps.value[key] = true;
        };

        const handleNextClick = (): void => {
            if (isExternalSupplier.value) {
                externalFormRef.value?.validateAndEmit();
                if (!isFormValid.value) {
                  return; // Stop if form is not valid
                }
              }
            changeStep(currentStep.value + 1);
        }

        const tipMessages = ref<string[]>();
        const showTipMessages = ref<boolean>(false);
        const marking = ref<Marking>(getMarking(new RouteParams(route.params).marking()));
        const selectedCalculator = ref<CalculateCurtainDropsApiOutput | null>(null);
        const selectedData = ref<FabricEditorSelectedData | null>(null);
        const currentStep = ref<number>(new RouteParams(route.params).step() || 1);
        const steps = ref<Record<number, boolean>>({
            1: false,
            2: false,
            3: false
        });
        steps.value[currentStep.value] = true;

        const folder = ref<Folder>(getFolder(marking.value.folderId));
        const markingFolderName = folder.value.parentId ? folder.value.name : "";

        const name = ref(marking.value.name);
        const orderLine = ref<OrderLine | undefined>(getOrderLine(marking.value.id));
        const selectedVariant = ref<ProductModel | undefined>();

        const markingSaving = ref<boolean>(false);
        const saveMarking = async (): Promise<void> => {
            marking.value.name = name.value;
            markingSaving.value = true;
            UIStore.setLoading(true);
            if (selectedProductId.value && selectedVariant.value && selectedCalculator.value && selectedData.value) {
              try  {
                await saveOrderLine(order.value.id, marking.value, markingFolderName, selectedProductId.value, selectedVariant.value, selectedCalculator.value, selectedData.value, getOrderLine(marking.value.id));
                orderLine.value = getOrderLine(marking.value.id);
                router.push({ name: "folderview", params: { order: order.value.id, folder: order.value.folder.id } });
              }
              catch(e: any) {
                await Swal.fire<void>(e.message, undefined, "info");
              }
              finally{
                UIStore.setLoading(false);
                markingSaving.value = false;
              }
            } else {
              router.push({ name: "folderview", params: { order: order.value.id, folder: order.value.folder.id } });
              UIStore.setLoading(false);
              markingSaving.value = false;
            }
        };

        const productSelectorSelectVariant = (product: ProductModel, variant: ProductModel): void => {
            selectedVariant.value = variant;
            selectedProductId.value = product.productId;
        };

        const onExternalSupplierChange = (value: boolean): void => {
            isExternalSupplier.value = value;
        }

        const handleFormValid = (isValid: boolean): void => {
            isFormValid.value = isValid;
          };

        const onFabricEditorCalculating = (state: boolean = true) => {
            calculationInProgress.value = state;
        }

        const onFabricEditorCalculated = (calculationOutput: CalculateCurtainDropsApiOutput, fabricSelectedData: FabricEditorSelectedData): void => {
            selectedCalculator.value = calculationOutput;
            selectedData.value = fabricSelectedData;
            calculationInProgress.value = false;
        };

        const onFabricEditorTipMessageShow = (tipMessagesInput: string[]): void => {
            showTipMessages.value = tipMessagesInput.length > 1 ? true : false;
            tipMessages.value = tipMessagesInput;
        };

        const selectedProductId = ref(orderLine.value?.ecomProductId ?? null);

        onBeforeRouteUpdate(async (to) => {
            marking.value = getMarking(new RouteParams(to.params).marking());
            name.value = marking.value.name;
            currentStep.value = 1;
        });

        const markingTotalPrice = computed(() => selectedCalculator.value?.totalPriceForMarking);

        const image = computed(() => {
            switch (currentStep.value) {
                case 2:
                    if (selectedVariant.value) {
                        return selectedVariant.value.largeImage;
                    }
            }
            return "";
        });

        const saveEnabled = computed<boolean>(() =>
            !calculationInProgress.value
            && selectedProductId.value != null
            && selectedVariant.value != null
            && selectedCalculator.value != null
        );

        const isNextButtonEnabled = computed<boolean>(() => {
            if (isExternalSupplier.value) {
                return name.value !== '' &&
                       !markingSaving.value &&
                       !calculationInProgress.value &&
                       isFormValid.value; 
            } else {
                return name.value !== '' &&
                       !markingSaving.value &&
                       !calculationInProgress.value; 
            }
        });
        

        const curtainVisualizerData = computed<CurtainVisualizerData | undefined>(() => {
            if (selectedVariant.value
                && selectedData.value
                && selectedCalculator.value
                && selectedCalculator.value.curtainDrops
                && selectedCalculator.value.curtainDrops.length > 0) {
                const curtainDrop = selectedCalculator.value.curtainDrops[0];
                let width = 0;
                if (selectedData.value.curtainConfiguration === ConfigurationType.CoverWall) {
                    width = selectedCalculator.value.totalFinishedWidth;
                } else {
                    width = curtainDrop.finishedWidthPerCurtainDrop;
                    if (selectedCalculator.value.splitCurtain) {
                        width = 2 * width;
                    }
                }
                width = width / 10;
                return {
                    width: width,
                    height: selectedCalculator.value.curtainDrops[0].finishedHeightPerCurtainDrop / 10,
                    sewingType: selectedCalculator.value.sewingType,
                    splitCurtain: selectedCalculator.value.splitCurtain,
                    imageUrl: selectedVariant.value.image,
                } as CurtainVisualizerData;
            }

            return undefined;
        });

         const step2ScrollModifications = computed(() => {
            return currentStep.value == 2 ? ['marking__scroll-container--fixed' , 'marking__scroll-container--fixed-bottom'] : '';
         });

         const previewDefaultClasses= computed(() => {
            const defaultClasses = {'step-2-default-img' : (image.value === '' && steps.value[2]), 'step-1-default-img': steps.value[1]};

            return defaultClasses
        })

        return {
            selectedProductId,
            selectedCalculator,
            order,
            folder,
            marking,
            name,
            image,
            steps,
            currentStep,
            changeStep,
            handleOnCloseClick,
            orderLine,
            saveMarking,
            productSelectorSelectVariant,
            onFabricEditorCalculating,
            onFabricEditorCalculated,
            saveEnabled,
            isNextButtonEnabled,
            selectedVariant,
            markingTotalPrice,
            handleNextClick,
            curtainVisualizerData,
            step2ScrollModifications,
            markingSaving,
            previewDefaultClasses,
            onFabricEditorTipMessageShow,
            showTipMessages,
            tipMessages,
            isExternalSupplier,
            onExternalSupplierChange, 
            isFormValid,
            handleFormValid
        }
    }
});

async function saveOrderLine(orderId: string, marking: Marking, folderName: string, productId: number, selectedVariant: ProductModel, config: CalculateCurtainDropsApiOutput, selectedData: FabricEditorSelectedData, orderLine?: OrderLine) {
    if (!orderLine || orderLine.ecomRowId == 0) {
        orderLine = {
            ecomProductId: productId,
            ecomRowId: null,
            erpProductId: selectedVariant.partNo,
            erpConfigurableProductId: selectedVariant.configurableProductId,
            markingId: marking.id,
            markingName: marking.name,
            folderName: folderName,
            quantity: config.totalQuantity,
            markingQuantity: config.markingQuantity,
            price: config.totalPriceForMarking,
            productName: selectedVariant.name,
            productWidth: selectedVariant.width,
            productRepeatHeight: selectedVariant.repeatHeight,
            recommendWeights: selectedVariant.recommendWeights, //use selectedData.useWeights here instead?
            curtainData: config,
            curtainConfiguration: selectedData.curtainConfiguration,
            wallHeight: selectedData.wallHeight,
            wallWidth: selectedData.wallWidth,
            heightDeduction: selectedData.heightDeduction ?? 0,
            sewTogether: selectedData.sewTogether,
            splitCurtain: selectedData.splitCurtain,
            useWeights: selectedData.useWeights

        } as OrderLine;
        await OrderService.createOrderLine(orderId, orderLine);
    } else {
        orderLine = {
            ...orderLine,
            ecomProductId: productId,
            erpProductId: selectedVariant.partNo,
            erpConfigurableProductId: selectedVariant.configurableProductId,
            markingName: marking.name,
            folderName: folderName,
            quantity: config.totalQuantity,
            markingQuantity: config.markingQuantity,
            price: config.totalPriceForMarking,
            productName: selectedVariant.name,
            productWidth: selectedVariant.width,
            productRepeatHeight: selectedVariant.repeatHeight,
            recommendWeights: selectedVariant.recommendWeights,
            curtainData: config,
            curtainConfiguration: selectedData.curtainConfiguration,
            wallHeight: selectedData.wallHeight,
            wallWidth: selectedData.wallWidth,
            heightDeduction: selectedData.heightDeduction,
            sewTogether: selectedData.sewTogether,
            splitCurtain: selectedData.splitCurtain,
            useWeights: selectedData.useWeights
        };
        await OrderService.updateOrderLine(orderId, orderLine);
    }

    await OrderService.loadOrder(orderId);
}
