<template>
  <div class="external-fabric-form">
    <form>
      <div class="form-grid">
        <div class="form-group">
          <label for="supplier">{{ $t("ExternalFabricForm:Supplier") }}</label>
          <input
            type="text"
            id="supplier"
            v-model="formData.supplier"
          />
        </div>

        <div class="form-group">
          <label for="product-name">{{ $t("ExternalFabricForm:ProductName") }}</label>
          <input
            type="text"
            id="product-name"
            v-model="formData.productName"
          />
        </div>

        <div class="form-group">
          <label for="article-number">{{ $t("ExternalFabricForm:ArticleNumber") }}</label>
          <input
            type="text"
            id="article-number"
            v-model="formData.articleNumber"
          />
        </div>

        <div class="form-group">
          <label for="color">{{ $t("ExternalFabricForm:Color") }}</label>
          <input
            type="text"
            id="color"
            v-model="formData.color"
          />
        </div>
      </div>

      <div class="form-group">
        <label>{{ $t("ExternalFabricForm:Width") }}</label>
        <div class="radio-group">
          <label class="radio__input-container">
            <input
              type="radio"
              value="150"
              v-model="formData.width"
            />
            <span class="radio__checkmark"></span>
            {{ $t("ExternalFabricForm:Width150") }}
          </label>
          <label class="radio__input-container">
            <input
              type="radio"
              value="300"
              v-model="formData.width"
            />
            <span class="radio__checkmark"></span>
            {{ $t("ExternalFabricForm:Width300") }}
          </label>
        </div>
      </div>
    </form>
  </div>
</template>

<script src="./ExternalFabricForm.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./ExternalFabricForm.scss";
</style>

