import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  props: {
    labelOn: { type: String, default: '' },
    labelOff: { type: String, default: '' },
    value: { type: Boolean, default: false }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const isChecked = ref(props.value);

    watch(
      () => props.value,
      (newValue) => {
        isChecked.value = newValue;
      }
    );

    const emitChange = () => {
      emit('change', isChecked.value);
    };

    return {
      isChecked,
      emitChange
    };
  }
});
