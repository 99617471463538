<template>
  <div class="toggle-switch-wrapper">
    <label class="toggle-switch">
      <input type="checkbox" v-model="isChecked" @change="emitChange" />
      <span class="toggle-slider"></span>
    </label>
    <span class="toggle-label" v-if="labelOn && labelOff">{{ isChecked ? labelOn : labelOff }}</span>
  </div>
</template>

<script src="./ToggleSwitch.ts" lang="ts"></script>

<style lang="scss" scoped>
@import './ToggleSwitch.scss';
</style>
