import { defineComponent } from "vue";
import { LsIntegrationService } from "@/axios/LsIntegrationService";
import { UserFriendlyError } from "@/models/errormodels";

const PingService = defineComponent({

    name: "PingService",

    data() {
        return {
          result: "", // Declare a result variable to store the API response
          errorMessage: "" // For any error messages
        };
    },
    methods: {
        async pingEpos() {
            try {
                const data = await LsIntegrationService.ping();
                this.result = data; // Store the result in the component's data                
            }
            catch (error){
                this.errorMessage = (error as UserFriendlyError)?.message;    
                console.log(error);              
            }
        }
    },
    mounted() {
        this.pingEpos();
    },
});

export default PingService;