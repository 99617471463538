import { Vue, Options } from "vue-class-component";
import { reactive, watch } from "vue";

@Options({
  emits: ["form-valid"],
})
export default class ExternalFabricForm extends Vue {
  protected formData = reactive({
    supplier: "",
    productName: "",
    articleNumber: "",
    color: "",
    width: "150", // Default value
  });

  protected validateForm(): boolean {
    // Control if all fields have a value
    return !!(
      this.formData.supplier &&
      this.formData.productName &&
      this.formData.articleNumber &&
      this.formData.color &&
      this.formData.width
    );
  }

  public validateAndEmit(): void {
    const isValid = this.validateForm();
    this.$emit("form-valid", isValid);
  }

  created() {
    watch(
      () => this.formData,
      () => {
        this.$emit("form-valid", this.validateForm());
      },
      { deep: true }
    );
  }
}
